import React, { useCallback, useEffect, useState } from 'react'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
import EmailDetail from './EmailDetail'
import style from './ManageProfile.module.scss'
import { Spinner } from 'reactstrap'
import { UserType } from '@digitalworkflow/dwloginclient'
import moment from 'moment'
import { getSentEmailBody, getSentEmails } from '../../utils/getSentEmails'

const defaulColDef = {
  sortable: true,
  unSortIcon: true,
  resizable: true,
  filter: 'agTextColumnFilter',
  minWidth: 140,
  floatingFilter: true
}

export interface IEmail {
  _id: string
  hash: string
  subject: string
  target: string
  cc: string
  bcc: string
  timestamp: string
  user_name: string
  body: string
}
interface Props {
  userInfo: UserType | null
}

const Emails: React.FC<Props> = ({ userInfo }: Props) => {
  const [emailDetail, setEmailDetail] = useState<IEmail | null>(null)
  const [emails, setEmails] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modifyLinks = (htmlContent: string): string => {
    // Use a regular expression to find all <a> tags and add target="_blank" if not already present
    return htmlContent.replace(/<a([^>]+)>/gi, (match, content) => {
      // Check if the target attribute is already present
      if (!/target=["']_blank["']/.test(content)) {
        return `<a${content} target="_blank">`
      }
      return match
    })
  }
  const handleViewDetail = useCallback(async (email: IEmail) => {
    const res = await getSentEmailBody({ _id: email._id })
    const userName = userInfo
      ? userInfo?.first_name + ' ' + userInfo?.last_name
      : ''
    setEmailDetail({
      ...email,
      body: modifyLinks(res.data.data || ''),
      user_name: userName
    })
  }, [])

  const getEmails = useCallback(async () => {
    try {
      if (userInfo) {
        setIsLoading(true)
        const filter = {
          target: userInfo.email,
          target_type: 'equals'
        }
        const res = await getSentEmails({
          page: 1,
          limit: 100,
          filter
        })

        if (res.data.data.length > 0) {
          setEmails(res.data.data)
        }

        setIsLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [userInfo])

  useEffect(() => {
    getEmails()
  }, [])

  const columnDefs: any[] = [
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 3
    },
    {
      field: 'timestamp',
      headerName: 'Time',
      sort: 'desc',
      flex: 2,
      filter: 'agDateColumnFilter',
      valueFormatter: (params: any) => {
        return params.data.timestamp
          ? moment(params.data.timestamp).format('LLL')
          : ''
      },
      cellRenderer: (params: any) => {
        return moment(params.data.timestamp).format('LLL')
      },
      filterParams: {
        filterOptios: ['equals'],
        maxValidDate: new Date(),
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (!cellValue) {
            return -1
          }
          const date = new Date(cellValue)
          const year = Number(date.getFullYear())
          const month = Number(date.getMonth())
          const day = Number(date.getDate())
          const cellDate = new Date(year, month, day)

          if (cellDate < filterLocalDateAtMidnight) {
            return -1
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1
          }

          return 0
        }
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      flex: 1,
      cellRenderer: (params: any) => (
        <div
          className='action-text'
          onClick={() => handleViewDetail(params.data)}
        >
          View Detail
        </div>
      )
    }
  ]

  return (
    <div className={style.portalContainer}>
      <div className={style.gridPortalContainer}>
        {isLoading ? (
          <div className='flex justify-center '>
            <Spinner />
          </div>
        ) : (
          <>
            {emailDetail ? (
              <EmailDetail
                emailDetail={emailDetail}
                handleRemoveDetail={() => setEmailDetail(null)}
              />
            ) : (
              <LoginPortalGrid
                rowData={emails}
                columnDefs={columnDefs}
                defaulColDef={defaulColDef}
                pagination={false}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default Emails
