/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
  RCDockLayout,
  DockLayout,
  TabData
} from '@digitalworkflow/dwreactcommon'
import {
  AuthService,
  UserService,
  WorkgroupService,
  WorkgroupType,
  UserType
} from '@digitalworkflow/dwloginclient'
import AddWorkgroup from '../../container/AddRole/AddWorkgroup'
import '../../style/adminSetting.scss'
import '../../style/roleContianer.scss'
import WorkgroupList from '../../container/RoleList/WorkgroupList'
import { RcGroup } from '../../components/RcGroup'
import EditWorkgroup from '../../container/EditRoleWokgroup/EditWorkgroup'
import WorkgroupRoleDetails from '../../container/WorkgroupRole/WorkgroupRoleDetails'
import LoginPortalGrid from '../../components/LoginPortalGrid/LoginPortalGrid'
import TabClose from '../../components/TabClose'
import AddUser from '../../container/EditRoleWokgroup/AddUser'
import { addFloatBox, getTab, removeRCTab } from '../../lib/rcDock-lib'
import { getSize } from '../../utils/getSize'
import { userPermissionOfReadOnly } from '../../utils/userPermissions'
import { LocalSettings } from '../../utils/LocalSettings'
import { Spinner } from 'reactstrap'

const authService = AuthService.instance()
const userService = new UserService()
const workgroupService = new WorkgroupService()
UserService.setAuthServiceInstance(authService)

const columnDefs = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 1,
    resizable: true,
    floatingFilter: true,
    comparator: (a: any, b: any) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    unSortIcon: true,
    filter: 'agTextColumnFilter',
    minWidth: 140,
    flex: 1,
    resizable: true,
    floatingFilter: true,
    comparator: (a: any, b: any) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
  }
  /** NOTE : Hide Invovled via for now, can be added later */
  // {
  //   field: 'involvedVia',
  //   headerName: 'Involved via',
  //   sortable: true,
  //   unSortIcon: true,
  //   filter: 'agTextColumnFilter',
  //   minWidth: 140,
  //   floatingFilter: true,
  //   cellRenderer: (params: any) => (
  //     <div>
  //       <span className='font-bold'>Role :</span>
  //       {params.data.involvedVia}
  //     </div>
  //   )
  // }
]

const ManageWorkgroup = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const workgroupListRef = useRef<any>(null)
  const [activeWorkgroup, setActiveWorkgroup] = useState<WorkgroupType | null>(
    null
  )
  const gridRef: any = useRef(null)
  const [workgroupMembers, setWorkgroupMembers] = useState(null)
  const [isAllowedToModify, setAllowedToModify] = useState<boolean>(false)
  const [manageUserAccess, setManageUserAccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isReadOnlyAccess = useMemo(() => {
    return userPermissionOfReadOnly('Manage Workgroups')
  }, [LocalSettings.dwhub_user])

  useEffect(() => {
    if (
      dockLayoutRef &&
      dockLayoutRef.current &&
      (activeWorkgroup || isLoading)
    ) {
      const _layouts = dockLayoutRef.current.getLayout()
      dockLayoutRef.current.loadLayout(_layouts)
      // dockLayoutRef.current.updateTab(
      //   'workgroup_detail',
      //   workgroupDetail.tabs[0]
      // )
      // dockLayoutRef.current.updateTab(
      //   'active_members',
      //   ActiveMembersList.tabs[0]
      // )
      const _editWorkgroup = dockLayoutRef.current.find(
        'editWorkgroup'
      ) as TabData
      if (_editWorkgroup) {
        dockLayoutRef.current.dockMove(_editWorkgroup, null, 'remove')
        removeRCTab(dockLayoutRef, 'editWorkgroup')
        handleEditFloatBox()
      }
    }
  }, [activeWorkgroup, workgroupMembers, isLoading])

  const getWorkgroupUsers = useCallback(
    async (workgroupId: string) => {
      const res = await userService.getUsersByWorkgroupId(workgroupId)
      if (res && res.data) {
        setWorkgroupMembers(
          (res.data as any)?.map((user: UserType) => {
            return {
              id: user.id,
              name: `${user?.first_name ?? ''} ${user?.last_name ?? ''}`,
              email: user.email
            }
          })
        )
      }
    },
    [isLoading, workgroupMembers]
  )

  const handleEditWorkgroup = useCallback(() => {
    handleEditFloatBox()
  }, [dockLayoutRef, activeWorkgroup])

  const handleAddWorkgroup = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      const layouts = dockLayoutRef.current.getLayout()
      const _workgroupListTab: any = layouts.maxbox?.children.find(
        (item: any) => item.activeId === 'workgroup_list'
      )
      if (_workgroupListTab && layouts.maxbox) {
        layouts.maxbox.children = layouts.maxbox?.children.filter(
          (item: any) => item.activeId !== 'workgroup_list'
        )
        layouts.dockbox.children = layouts.dockbox?.children.map(
          (item: any) => {
            item = item.activeId === 'workgroup_list' ? workgroupList : item
            if (item?.children) {
              item.children = item.children.map((i: any) => {
                const _child =
                  i.activeId === 'workgroup_list' ? workgroupList : i
                return _child
              })
            }
            return item
          }
        )

        dockLayoutRef.current.loadLayout(layouts)
      }
    }
    handleAddFloatBox()
  }

  const handleEditFloatBox = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      if (!dockLayoutRef.current.find('editWorkgroup')) {
        dockLayoutRef.current.dockMove(
          {
            tabs: [
              {
                id: 'editWorkgroup',
                title: (
                  <>
                    <span>Edit Workgroup</span>
                    <TabClose
                      dockLayoutRef={dockLayoutRef}
                      id='editWorkgroup'
                    />
                  </>
                ),
                content: (
                  <EditWorkgroup
                    activeWorkgroup={activeWorkgroup}
                    handleNewWorkgroup={handleNewWorkgroup}
                    dockLayoutRef={dockLayoutRef.current}
                    id='editWorkgroup'
                  />
                ),
                group: 'close-all'
              }
            ],
            group: 'close-all',
            x: 200,
            y: 10,
            w: 500,
            h: 440,
            z: 9999
          },
          null,
          'float'
        )
      }
    }
  }

  const handleAddUserFloatBox = () => {
    addFloatBox(
      dockLayoutRef,
      'add_users',
      getTab(
        'add_users',
        'Add Users',
        dockLayoutRef,
        () => (
          <AddUser
            activeWorkgroup={activeWorkgroup}
            handleAddUser2Workgroup={handleAddUser}
            id='addUser'
          />
        ),
        700,
        200,
        750,
        10
      )
    )
  } // handle add user float box ends
  const handleAddFloatBox = () => {
    if (dockLayoutRef && dockLayoutRef.current) {
      if (!dockLayoutRef.current.find('addWorkgroup')) {
        const _maximized: any = dockLayoutRef.current.find(
          '-maximized-placeholder-'
        )
        if (_maximized) {
          dockLayoutRef.current.dockMove(_maximized as any, null, 'remove')
        }
        dockLayoutRef.current.dockMove(
          {
            tabs: [
              {
                id: 'addWorkgroup',
                title: (
                  <>
                    <span>Add Workgroup</span>
                    <TabClose dockLayoutRef={dockLayoutRef} id='addWorkgroup' />
                  </>
                ),
                content: (
                  <AddWorkgroup
                    handleRemoveWorkgroup={handleRemoveWorkgroup}
                    handleNewWorkgroup={handleNewWorkgroup}
                  />
                ),
                group: 'close-all'
              }
            ],
            group: 'close-all',
            x: 50,
            y: 10,
            w: 900,
            h: 780
          },
          null,
          'float'
        )
      }
    }
  }

  const handleNewWorkgroup = async () => {
    if (workgroupListRef && workgroupListRef.current) {
      await workgroupListRef.current.getWorkgroups()
    }
  }

  const handleAddUser = async (values: any) => {
    try {
      const wgRes = await userService.assignWorkgroup(
        values.id,
        activeWorkgroup?.id as string
      )

      if (wgRes.is_error) {
        toast.error(
          wgRes.message ?? 'Error occured while adding user to workgroup'
        )
      } else {
        toast.success('User added to workgroup successfully')
        getWorkgroupUsers(activeWorkgroup?.id ?? '')
      }
    } catch (e) {
      console.log('Exception while adding user to group: ', e)
      toast.error('An unexpected error occured')
    }
  } // handle add user ends

  const handleRemoveWorkgroup = () => {
    const currentLayout = dockLayoutRef.current?.getLayout()
    if (currentLayout && currentLayout.floatbox) {
      const _index = currentLayout.floatbox.children.findIndex(
        (item: any) => item.id === 'addWorkgroup'
      )
      currentLayout.floatbox.children.splice(_index, 1)
    }
    if (dockLayoutRef && dockLayoutRef.current) {
      dockLayoutRef.current.setLayout(currentLayout as any)
      dockLayoutRef.current.loadLayout(currentLayout as any)
    }
  }

  const handleActiveWorkgroup = useCallback(
    async (workgroup: WorkgroupType) => {
      setIsLoading(true)
      if (workgroup.id) {
        await getWorkgroupUsers(workgroup.id)
        const res = await workgroupService.permissionToModify(workgroup.id)
        if (res.is_error) {
          setAllowedToModify(false)
          setManageUserAccess(false)
        } else {
          setAllowedToModify(res.data.workgroup_access)
          setManageUserAccess(res.data.user_access)
        }
      }
      setActiveWorkgroup(workgroup)
      setIsLoading(false)
    },
    [
      setActiveWorkgroup,
      activeWorkgroup,
      isAllowedToModify,
      manageUserAccess,
      isLoading
    ]
  )

  const workgroupList = {
    size: 25,
    children: [
      {
        id: 'workgroupListTab',
        tabs: [
          {
            id: 'workgroup_list',
            title: 'Workgroup list',
            content: (
              <WorkgroupList
                handleAddWorkgroup={handleAddWorkgroup}
                ref={workgroupListRef}
                handleActiveWorkgroup={handleActiveWorkgroup}
                isReadOnlyAccess={isReadOnlyAccess}
              />
            ),
            group: 'close-all'
          }
        ]
      }
    ]
  }

  const ActiveMembersList = {
    id: 'workgroupSecondTab',
    size: 75,
    tabs: [
      {
        id: 'active_members',
        title: 'Active Members',
        content: (
          <>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Spinner />
              </div>
            ) : (
              <LoginPortalGrid
                gridRef={gridRef}
                rowData={workgroupMembers}
                columnDefs={columnDefs}
              />
            )}
          </>
        ),
        group: 'close-all'
      }
    ]
  }

  const workgroupDetail = {
    size: 10,
    id: 'workgroup_detail_container',
    panelLock: true,
    tabs: [
      {
        id: 'workgroup_detail',
        title: 'Workgroup Detail',
        content: activeWorkgroup ? (
          <WorkgroupRoleDetails
            isAllowedToModify={isAllowedToModify}
            isReadOnlyAccess={isReadOnlyAccess}
            isLoading={isLoading}
            handleEditWorkgroupRole={handleEditWorkgroup}
            handleAddUserFloatBox={handleAddUserFloatBox}
            activeWorkgroupRole={activeWorkgroup}
            editButtonText='Edit Workgroup'
            addUsersButtonText='Add Users'
            isWorkgroup
            manageUserAccess={manageUserAccess}
            dockLayoutRef={dockLayoutRef}
          />
        ) : (
          <span className='defaultBlankText'>
            Details are based on selected workgroup from the list
          </span>
        ),
        minWidth: 570,
        minHeight: 118,
        group: 'close-all'
      }
    ]
  }

  const defaultLayout: any = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          mode: 'vertical',
          size: getSize(2300, 1300),
          children: [
            {
              tabs: [
                {
                  id: 'description',
                  title: 'MANAGE WORKGROUPS',
                  content: (
                    <div className='p-2'>
                      <div className='mt-1'>
                        <h6>Workgroups</h6>
                      </div>
                      <p className='descripton'>
                        Workgroup defines what users are allowed to do. It's an
                        organized group of coworkers. The hierarchy come in to
                        place here. The person belong to parent workgroup can
                        see everything of children workgroup but the person with
                        child workgroup can't see what parent workgroup can see!
                      </p>
                    </div>
                  ),
                  panelStyle: 'bg-transparent',
                  group: 'headless'
                }
              ]
            }
          ]
        },
        {
          mode: 'horizontal',
          children: [
            workgroupList,
            {
              mode: 'vertical',
              size: 75,
              children: [{ ...workgroupDetail }, { ...ActiveMembersList }]
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <RCDockLayout
      defaultLayout={defaultLayout}
      dockLayoutRef={dockLayoutRef}
      groups={RcGroup}
    />
  )
}
export default ManageWorkgroup
